import React from "react";
import { Link } from "gatsby";
import { FaChevronRight } from "react-icons/fa/index.esm";

const CtaButton = ({ label = "View", className = "", url }) => (
  <Link to={url} className={`cta-button ${className}`}>
    <i>
      <FaChevronRight />
    </i>
    <span className="ml-1">{label} </span>
  </Link>
);

export default CtaButton;
