import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

import Img from "components/Image";
import CtaButton from "components/CtaButton";
import Layout from "components/Layout";
import SEO from "components/Seo";
import { HTMLContent } from "components/Content";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cuffsPic: file(name: { eq: "cuffing" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      prismicAboutus {
        data {
          intro_title {
            text
          }
          intro_body {
            html
          }
          quick_facts {
            html
          }
          body {
            ... on PrismicAboutusBodyTextOnly {
              id
              slice_type
              primary {
                to_body {
                  html
                }
                to_title {
                  text
                }
              }
            }
            ... on PrismicAboutusBodyTextwithpic {
              id
              slice_type
              primary {
                tp_body {
                  html
                }
                tp_title {
                  text
                }
                tp_image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // console.log(data.prismicAboutus);
  const IS_BROWSER = typeof window !== "undefined";
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const staticData = data;

  const contentMerged = mergePrismicPreviewData({ staticData, previewData });
  const content = contentMerged.prismicAboutus.data;
  // console.log("content", content);

  // filter out the slices by type, since there's only one of each
  // note: could later filter by title, if more are added?
  const trainingBlock = content.body.find(x => x.slice_type === "textwithpic");
  const offsiteBlock = content.body.find(x => x.slice_type === "text_only");

  return (
    <Layout pageInfo={{ pageName: "about-us" }}>
      <SEO title="About Us" />
      {/* <Banner title="About Us" /> */}
      <section className="about-main">
        <Container>
          <Row>
            <Col xs="12" md="7">
              <h2>About Us</h2>

              <h4 className="subhead">{content.intro_title.text}</h4>

              <HTMLContent content={content.intro_body.html} />
            </Col>
            <Col md={{ span: 4, offset: 1 }}>
              <h4>Quick Facts:</h4>
              <HTMLContent content={content.quick_facts.html} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col md="7" className="text-center">
              <p className="mini-lead">Learn From the Best</p>
              <h3>{trainingBlock.primary.tp_title.text}</h3>
              <HTMLContent content={trainingBlock.primary.tp_body.html} />

              <CtaButton
                label="View Classes"
                className="mt-3"
                url="/class-schedules"
              />
            </Col>
            <Col xs="12" md={{ span: 4, offset: 1 }}>
              <Img
                fluid={
                  trainingBlock.primary.tp_image.localFile &&
                  trainingBlock.primary.tp_image.localFile.childImageSharp.fluid
                }
                title={trainingBlock.primary.tp_title.text}
                alt={trainingBlock.primary.tp_title.text}
                className="thumbnail-img-wrap"
                style={{ margin: "0" }}
                src={
                  !trainingBlock.primary.tp_image.localFile &&
                  trainingBlock.primary.tp_image.url
                }
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="dark">
        <Container>
          <Row>
            <Col md="10" className="text-center mx-auto">
              <p className="mini-lead">Also Available</p>
              <h3>{offsiteBlock.primary.to_title.text}</h3>

              <HTMLContent content={offsiteBlock.primary.to_body.html} />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="10" className="mx-auto text-center">
              <p className="mini-lead">Get Started</p>
              <h2>Your Future in Security Starts Here</h2>
              <p className="intro">
                Enroll now to start your journey towards a better future.
              </p>

              <CtaButton
                label="View Classes"
                url="/class-schedules"
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default AboutPage;
